import React from 'react';
import Styles from '../Styles/FailedPaymentCard.module.css';
import {ErrorIcon} from './Icons/Index';

const FailedPaymentCard: React.FC = () => {
  return (
    <div className={Styles.FailedPaymentCard}>
      <div className={Styles.FailedPaymentCardHeader}>
        <ErrorIcon fill="#DB1818" style={{width: '2.5rem', height: '2.5rem'}} />
        <p className={Styles.FailedPaymentCardTitle}> An Error has occurred</p>
      </div>

      <div className={Styles.FailedPaymentCardBody}>
        <p>
          There seems to be a problem with your payment. Please try again. If
          the problem persists try re-sending the request from the portal again
        </p>
      </div>
    </div>
  );
};

export default FailedPaymentCard;
