import APIAxios from '../utils/apiaxios';
import {AxiosResponse, AxiosError} from 'axios';

interface getSessionIdData {
  data: {
    id: string | number;
  };
  onSuccess: (res: AxiosResponse) => void;
  onError: (err: AxiosError) => void;
}
interface sendPaymentStatusData {
  data: {
    id: string | number;
    status: string;
  };
  onSuccess: (res: AxiosResponse) => void;
  onError: (err: AxiosError) => void;
}

interface cardUserData {
  data: {
    id: string | number;
    email: string;
  };
  onSuccess: (res: AxiosResponse) => void;
  onError: (err: AxiosError) => void;
}

export const getSessionId = async (args: getSessionIdData) => {
  await APIAxios({
    url: '/get-session-id',
    method: 'post',
    data: args.data,
  })
    .then(args.onSuccess)
    .catch(args.onError);
};

export const sendCardPaymentStatus = async (args: sendPaymentStatusData) => {
  await APIAxios({
    url: '/card-payment-status',
    method: 'post',
    data: args.data,
  })
    .then(args.onSuccess)
    .catch(args.onError);
};

export const addCardUserEmail = async (args: cardUserData) => {
  await APIAxios({
    url: '/add-card-user-email',
    method: 'post',
    data: args.data,
  })
    .then(args.onSuccess)
    .catch(args.onError);
};
