import * as React from 'react';

interface Props {
  fill?: string;
  style?: {[key: string]: string | number};
}
const RightArrow = (props: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14.626}
    height={14.626}
    style={props.style}
  >
    <path
      data-name="Path 1059"
      d="m7.313 13.212 5.9-5.899-5.9-5.898"
      fill="none"
      stroke={props.fill || '#fff'}
      strokeLinecap="round"
      strokeWidth={2}
    />
  </svg>
);

export default RightArrow;
