import * as React from 'react';
import Logo from './Wayame-02.svg';
interface Props {
  fillOne?: string;
  fillTwo?: string;
  fillThree?: string;
  style?: {[key: string]: string | number};
  className?: string;
}
const LogoComponent = (props: Props) => (
  <div
    style={{
      maxWidth: '180px',
      width: '180px',
    }}
  >
    <img src={Logo} alt="App Logo" />
  </div>
);

export default LogoComponent;
