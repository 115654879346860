import React, {PropsWithChildren, SetStateAction} from 'react';
import {socket} from '../utils/apiaxios';
export interface App {
  carts?: {name: string; count: string | number; price: string | number}[];
  id: number | string;
  referral_url: string;
  payment_option: string | null;
  itemCount?: number | null;
  totalPrice?: number | null;
  sendOTP?: boolean;
  paymentError?: boolean;
  setPaymentError?: React.Dispatch<SetStateAction<boolean>>;
  paymentType?: {
    selected: boolean;
    type: string;
  };
  setPaymentType?: React.Dispatch<
    SetStateAction<{selected: boolean; type: string}>
  >;

  saveToLocalStorage?: (name: string, item: string) => void;
  getLocalStorageItem?: (name: string) => unknown;
  socketConnected: boolean;
  setSocketConnected: React.Dispatch<SetStateAction<boolean>>;
  sessionTime: number;
  setSessionTime: React.Dispatch<SetStateAction<number>>;
  sessionExpiryTime: Date;
  setSessionExpiryTime: React.Dispatch<SetStateAction<Date>>;
}

const AppContext = React.createContext<App>({
  carts: [],
  id: '',
  referral_url: '',
  payment_option: null,
  itemCount: null,
  totalPrice: null,
  sendOTP: true,
  paymentError: false,
  setPaymentError() {},
  paymentType: {
    selected: false,
    type: '',
  },
  setPaymentType() {},
  saveToLocalStorage() {},
  getLocalStorageItem() {},
  socketConnected: false,
  setSocketConnected() {},
  sessionTime: 0,
  setSessionTime() {},
  sessionExpiryTime: new Date(),
  setSessionExpiryTime() {},
});

//Provider

export const AppContextProvider: React.FC<PropsWithChildren> = props => {
  const [id, setID] = React.useState('');
  const [carts, setCarts] = React.useState([]);
  const [referral_url, setReferralUrl] = React.useState('');
  const [payment_option, setPaymentOption] = React.useState('');
  const [itemCount, setItemCount] = React.useState(null);
  const [totalPrice, setTotalPrice] = React.useState(null);
  const [sendOTP, setSendOTP] = React.useState(true);
  const [paymentError, setPaymentError] = React.useState(false);
  const [paymentType, setPaymentType] = React.useState({
    selected: false,
    type: '',
  });
  // session
  const [sessionTime, setSessionTime] = React.useState(30);
  const d = new Date();
  d.setMinutes(d.getMinutes() + sessionTime);
  const [sessionExpiryTime, setSessionExpiryTime] = React.useState(d);

  // socket
  const [socketConnected, setSocketConnected] = React.useState(false);

  socket.on('connect', () => {
    console.log('socket connected');
    setSocketConnected(true);
  });
  socket.on('disconnect', () => {
    setSocketConnected(false);
  });
  socket.on('connect_error', () => {
    setSocketConnected(false);
    console.log('socket error');
  });

  React.useEffect(() => {
    const a = document.getElementById('payload');
    const payload = JSON.parse(a?.innerText || '{}');

    if (payload?.carts) {
      setID(payload.id);
      setCarts(payload.carts._products);
      setItemCount(payload.carts._item_count);
      setTotalPrice(payload.carts._total_price);
      setSendOTP(payload.send_otp);
      setReferralUrl(payload.referral_url);
      setPaymentOption(payload.payment_option);
      setSessionTime(Math.floor(parseInt(payload.session_timeout) / 60) + 1);
      const expiryTime = new Date();
      expiryTime.setMinutes(
        expiryTime.getMinutes() +
          (Math.floor(parseInt(payload.session_timeout) / 60) + 1)
      );

      setSessionExpiryTime(expiryTime);
      localStorage.setItem('NSPexpiryTime', JSON.stringify(expiryTime));
      localStorage.setItem('payload', JSON.stringify(payload));
    } else {
      const localPayload = localStorage.getItem('payload');
      const expiryTime = localStorage.getItem('NSPexpiryTime');
      if (localPayload) {
        const storedPayload = JSON.parse(localPayload);
        setID(storedPayload.id);
        setCarts(storedPayload.carts._products);
        setItemCount(storedPayload.carts._item_count);
        setTotalPrice(storedPayload.carts._total_price);
        setSendOTP(storedPayload.send_otp);
        setReferralUrl(storedPayload.referral_url);
        setPaymentOption(storedPayload.payment_option);
        setSessionTime(
          Math.floor(parseInt(storedPayload.session_timeout) / 60) + 1
        );
        if (expiryTime) {
          console.log('expiry available');
          setSessionExpiryTime(JSON.parse(expiryTime));
        } else {
          console.log('expiry not available');
          const expiryTime = new Date();
          expiryTime.setMinutes(
            expiryTime.getMinutes() +
              (Math.floor(parseInt(storedPayload.session_timeout) / 60) + 1)
          );
          localStorage.setItem('NSPexpiryTime', JSON.stringify(expiryTime));
          setSessionExpiryTime(expiryTime);
        }
      } else {
        setCarts([]);
      }
    }
  }, []);

  return (
    <AppContext.Provider
      value={{
        carts,
        id,
        itemCount,
        totalPrice,
        sendOTP,
        paymentError,
        setPaymentError,
        paymentType,
        setPaymentType,
        socketConnected,
        setSocketConnected,
        referral_url,
        payment_option,
        sessionTime,
        setSessionTime,
        sessionExpiryTime,
        setSessionExpiryTime,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

// HOOK

export const useGlobalState = () => React.useContext(AppContext);
