import APIAxios from '../utils/apiaxios';
import {AxiosResponse, AxiosError} from 'axios';

interface SendOTPData {
  data: {
    id: string | number;
    payby: string;
    customer_number: string | number;
  };
  onSuccess: (res: AxiosResponse) => void;
  onError: (err: AxiosError) => void;
}
interface VerifyOTPData {
  data: {
    id: string | number;
    code: string | number;
  };
  onSuccess: (res: AxiosResponse) => void;
  onError: (err: AxiosError) => void;
}
export const SendOTP = async (args: SendOTPData) => {
  await APIAxios({
    url: '/send-otp',
    method: 'post',
    data: args.data,
  })
    .then(args.onSuccess)
    .catch(args.onError);
};

export const VerifyOTp = async (args: VerifyOTPData) => {
  await APIAxios({
    url: '/verify-otp',
    method: 'post',
    data: args.data,
  })
    .then(args.onSuccess)
    .catch(args.onError);
};
