import * as React from 'react';

interface Props {
  fill?: string;
  style?: {[key: string]: string | number};
}
const FormatList = (props: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23.657"
    height="18.82"
    style={props.style}
  >
    <path
      d="M0 16.937a1.919 1.919 0 1 1 1.919 1.883A1.907 1.907 0 0 1 0 16.937Zm5.755 1.568v-3.071h17.9v3.071ZM0 9.405a1.919 1.919 0 1 1 1.919 1.883A1.9 1.9 0 0 1 0 9.41Zm5.755 1.539V7.939h17.9v3.005ZM0 1.879a1.919 1.919 0 1 1 1.919 1.88A1.9 1.9 0 0 1 0 1.883Zm5.755 1.638V.265h17.9v3.252Z"
      fill={props.fill || '#18a306'}
    />
  </svg>
);

export default FormatList;
