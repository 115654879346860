import React from 'react';
import PageNav from './components/PageNav';
import PaymentNav from './components/PaymentNav';
import Styles from '../../Styles/Paage.module.css';
import Carts from './components/Carts';
import FailedPaymentCard from '../FailedPaymentCard';
import {useLocation} from 'react-router-dom';
import {useGlobalState} from '../../context/AppContext';

export default function WithPage(Component: React.ComponentType) {
  function PageComponent(props: {}) {
    const {paymentError} = useGlobalState();
    const location = useLocation();

    return (
      <div className={Styles.page}>
        <PageNav />
        {paymentError &&
        (location.pathname === '/payment-details' ||
          location.pathname === '/card-payment') ? (
          <>
            <FailedPaymentCard />
          </>
        ) : null}
        <div className={Styles.checkoutHeader}>
          <h1>Checkout</h1>
        </div>

        <main>
          <div className={Styles.mainContent}>
            <section className={Styles.pageItem}>
              <PaymentNav />
              <Component {...props} />
            </section>
            <section className={Styles.pageCarts}>
              <Carts />
            </section>
          </div>
        </main>
      </div>
    );
  }

  return PageComponent;
}
