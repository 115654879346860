import React, {useEffect} from 'react';
import WithPage from '../components/Layouts/WithPage';
import {useGlobalState} from '../context/AppContext';
import {useNavigate} from 'react-router-dom';

function CardPayment() {
  const {paymentType, setPaymentType} = useGlobalState();
  const navigate = useNavigate();
  useEffect(() => {
    if (paymentType?.selected) {
      window.Checkout.showEmbeddedPage('#cardPayment');
    } else {
      navigate('/');
    }
  }, []);

  return (
    <div
      id="cardPayment"
      style={{marginTop: '2rem', marginBottom: '2rem'}}
    ></div>
  );
}

export default WithPage(CardPayment);
