import React from 'react';

interface Props {
  fill?: string;
  style?: {[key: string]: string | number};
}
function CheckMark(props: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10.257"
      height="7.854"
      viewBox="0 0 10.257 7.854"
      style={props.style}
    >
      <path
        fill={props.fill || '#fff'}
        d="M20.637 13.126l-.838-.862a.18.18 0 00-.133-.057.173.173 0 00-.133.057l-5.811 5.854L11.606 16a.184.184 0 00-.267 0l-.848.848a.19.19 0 000 .271l2.667 2.667a.844.844 0 00.557.271.884.884 0 00.553-.262l6.368-6.4a.2.2 0 00.001-.269z"
        data-name="Icon ionic-ios-checkmark"
        transform="translate(-10.434 -12.206)"
      ></path>
    </svg>
  );
}

export default CheckMark;
