import * as React from 'react';

interface Props {
  fill?: string;
  style?: {[key: string]: string | number};
  className?: string;
}
const SvgComponent = (props: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={22}
    {...props}
    style={props.style}
  >
    <path
      d="M9.998 21.001v-9h-9a1 1 0 0 1 0-2h9v-9a1 1 0 0 1 2 0v9h9a1 1 0 0 1 0 2h-9v9a1 1 0 0 1-2 0Z"
      fill={props.fill || '#6f7689'}
    />
  </svg>
);

export default SvgComponent;
