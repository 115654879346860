import * as React from 'react';

interface Props {
  fill?: string;
  style?: {[key: string]: string | number};
}

const Phone = (props: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19.975"
    height="22"
    style={props.style}
  >
    <path
      data-name="Path 2"
      d="m18.1 6.7-1 1a4.587 4.587 0 0 1 0 6.5l1 1a5.754 5.754 0 0 0 0-8.5ZM16 8.8l-1 1a1.951 1.951 0 0 1 0 2.3l1 1a3.018 3.018 0 0 0 0-4.3ZM12 0H2a2.006 2.006 0 0 0-2 2v18a2.006 2.006 0 0 0 2 2h10a2.006 2.006 0 0 0 2-2V2a2.006 2.006 0 0 0-2-2Zm0 19H2V3h10Z"
      fill={props.fill || '#0f0f10'}
    />
  </svg>
);

export default Phone;
