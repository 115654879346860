import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Styles from '../Styles/pages/paymentDetails.module.css';
import {css} from '@emotion/css';
import {
  Divider,
  Button,
  RadioGroup,
  FormControlLabel,
  InputAdornment,
  TextField,
  Typography,
  Skeleton,
} from '@mui/material';
import WithPage from '../components/Layouts/WithPage';
import MTN from '../Assets/Images/network-operator-mtn.png';
import VodaFone from '../Assets/Images/network-operator-vodafone.png';
import AirtelTigo from '../Assets/Images/network-operator-airteltigo.png';
import {
  CheckMarkCircle,
  LeftArrow,
  CheckMark,
  ErrorIcon,
} from '../components/Icons/Index';
import {useForm} from 'react-hook-form';
import Lottie from 'lottie-react';
import Spinner from '.././Assets/lotties/Spinner.json';
import OtpModal from '../components/modals/OtpModal';
import InitializePaymentModal from '../components/modals/InitPaymentModal';
import {toGHInternationalFormat} from '../utils/validation';
import {toast} from 'react-toastify';
import {useGlobalState} from '../context/AppContext';
import {SendOTP} from '../api/otp';
import {socket} from '../utils/apiaxios';

function PaymentDetails() {
  const {
    id,
    totalPrice,
    paymentError,
    setPaymentError,
    sendOTP,
    socketConnected,
  } = useGlobalState();
  const navigate = useNavigate();
  const [networkType, setNetworkType] = useState<string>('');
  const [verifiedNumber, setVerifiedNumber] = useState<boolean>(false);
  const [openVerify, setOpenVerify] = useState(false);
  const [otpErrorMessage, setOtpErrorMessage] = useState('');
  const [openInitPayment, setOpenInitPayment] = useState(false);
  const [requestOtpLoading, setRequestOtpLoading] = useState<boolean>(false);
  const [responseError, setResponseError] = useState<boolean>(false);
  const [showShimmerEffect, setShowShimmerEffect] = useState({
    mtnLogo: true,
    vodaLogo: true,
    airtelLogo: true,
  });
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
  type FormValues = {
    momoNumber: number | string;
  };
  const {
    register,
    handleSubmit,
    reset,
    // setValue,
    getValues,
    formState: {errors},
  } = useForm<FormValues>({
    mode: 'all',
  });
  const inputfield = register('momoNumber', {
    required: {
      value: true,
      message: 'Please enter your MoMo number',
    },
    maxLength: {
      value: 15,
      message: 'Please enter a valid MoMo number',
    },
    minLength: {
      value: 10,
      message: 'Please enter a valid MoMo number',
    },
    validate: value => {
      const v = value.toString();
      if (v.match(/^[0-9]+$/)) {
        return true;
      } else {
        return 'Please enter a valid MoMo number';
      }
    },
  });
  const closeVerifyModal = () => {
    reset();
    setNetworkType('');
    setVerifiedNumber(false);
    setOpenVerify(false);
    setResponseError(false);
  };
  const upDateNetworkType = (type: string) => {
    if (paymentError) setPaymentError && setPaymentError(false);
    if (loadingSubmit) setLoadingSubmit(false);
    setNetworkType(type);
  };
  const throwError = (msg: string) => {
    setResponseError(true);
    setRequestOtpLoading(false);
    setOpenVerify(false);
    setOtpErrorMessage(msg);
    setLoadingSubmit(false);
  };
  const onSubmit = (data: FormValues) => {
    setLoadingSubmit(true);
    if (paymentError) setPaymentError && setPaymentError(false);

    if (socket.connected) {
      socket.emit('join', {
        id: id,
        customer_number: toGHInternationalFormat(getValues().momoNumber),
        payby: networkType.toUpperCase(),
      });
      socket.on('join', data => {
        if (data.status === 'success') {
          socket.on('initiate-payment', initData => {
            // const d = JSON.parse(initData);
            if (initData.Status === 'Accepted' || initData.status === true) {
              setOpenInitPayment(true);
              setLoadingSubmit(false);
            } else {
              setPaymentError && setPaymentError(true);
              setLoadingSubmit(false);
            }
          });
        } else {
          setPaymentError && setPaymentError(true);
          setLoadingSubmit(false);
        }
      });
    } else {
      toast.error('An error occured, please try again');
      setLoadingSubmit(false);
    }
  };

  const sendOtpCode = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setResponseError(false);
    setRequestOtpLoading(true);

    SendOTP({
      data: {
        id: id,
        payby: networkType.toUpperCase(),
        customer_number: toGHInternationalFormat(getValues().momoNumber),
      },
      onSuccess: res => {
        if (res?.status === 200) {
          setOpenVerify(true);
          setRequestOtpLoading(false);
        } else {
          throwError('An error occured, please try again');
        }
      },
      onError: err => {
        throwError('An error occured, please try again');
      },
    });
  };

  const disablePayButton = (): boolean => {
    if (sendOTP) {
      if (verifiedNumber === true && openInitPayment === false) {
        return false;
      }
      return true;
    } else {
      if (
        getValues().momoNumber === undefined ||
        Boolean(errors.momoNumber) ||
        openInitPayment === true
      ) {
        return true;
      } else {
        return false;
      }
    }
  };
  return (
    <section
      className={css`
        margin-top: 3rem;
      `}
    >
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <div>
          <p
            className={`
             pageTitle
            `}
          >
            Payment details
          </p>
          <p className="pageSubTitle">Enter payment details</p>
          <Divider />
        </div>

        <div
          className={css`
            margin-top: 1rem;
          `}
        >
          <p
            style={{
              color: '#0f0f10',
              marginBottom: showShimmerEffect.mtnLogo ? '1.8rem' : '0.8rem',
              fontWeight: '500',
              fontSize: '14px',
            }}
          >
            Choose your network:
          </p>
          <RadioGroup
            aria-labelledby="select-network-type"
            name="Network Type"
            value={networkType}
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '0.8rem',
              marginLeft: '0.5rem',
            }}
          >
            <FormControlLabel
              onClick={() => {
                upDateNetworkType('mtn');
              }}
              className={Styles.controlLabel}
              value="mtn"
              control={
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <div>
                    <img
                      src={MTN}
                      onLoad={e => {
                        e.preventDefault();
                        setShowShimmerEffect({
                          ...showShimmerEffect,
                          mtnLogo: false,
                        });
                      }}
                      alt="MTNLogo"
                      className={Styles.logoStyle}
                    />
                    {showShimmerEffect.mtnLogo ? (
                      <Skeleton
                        variant="rectangular"
                        width={78}
                        height={41}
                        className={Styles.shimmerStyle}
                      />
                    ) : (
                      <></>
                    )}
                    <span className="chooseNetwork"></span>

                    <span
                      style={
                        networkType === 'mtn'
                          ? {
                              background: '#1F6FDE',
                              border: '2px solid #fff',
                              transform: 'scale(1.5)',
                            }
                          : {
                              background: 'rgba(0,0,0,0.2)',
                              border: '2px solid rgba(255,255,255,0.09)',
                              transform: 'scale(1)',
                            }
                      }
                      className={Styles.checkMarkStyle}
                    >
                      <CheckMark fill="#fff" />
                    </span>
                  </div>

                  <span
                    style={
                      networkType === 'mtn'
                        ? {
                            fontSize: '14px',
                            color: '#0f0f10',
                          }
                        : {
                            fontSize: '14px',
                            color: '#6F7689',
                          }
                    }
                  >
                    MTN
                  </span>
                </div>
              }
              label=""
            />

            <FormControlLabel
              onClick={() => {
                upDateNetworkType('airtel-tigo');
              }}
              className={Styles.controlLabel}
              value="airtel-tigo"
              control={
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <div>
                    <img
                      src={AirtelTigo}
                      alt="AirtelTigoLogo"
                      onLoad={e => {
                        e.preventDefault();
                        setShowShimmerEffect({
                          ...showShimmerEffect,
                          airtelLogo: false,
                        });
                      }}
                      className={Styles.logoStyle}
                    />
                    {showShimmerEffect.airtelLogo ? (
                      <Skeleton
                        variant="rectangular"
                        width={78}
                        height={41}
                        className={Styles.shimmerStyle}
                      />
                    ) : (
                      <></>
                    )}
                    <span className="chooseNetwork"></span>

                    <span
                      style={
                        networkType === 'airtel-tigo'
                          ? {
                              background: '#1F6FDE',
                              border: '2px solid #fff',
                              transform: 'scale(1.5)',
                            }
                          : {
                              background: 'rgba(0,0,0,0.2)',
                              border: '2px solid rgba(255,255,255,0.09)',
                              transform: 'scale(1)',
                            }
                      }
                      className={Styles.checkMarkStyle}
                    >
                      <CheckMark fill="#fff" />
                    </span>
                  </div>
                  <span
                    style={
                      networkType === 'airtel-tigo'
                        ? {
                            color: '#0f0f10',
                            fontSize: '14px',
                          }
                        : {
                            color: '#6F7689',
                            fontSize: '14px',
                          }
                    }
                  >
                    AirtelTigo
                  </span>
                </div>
              }
              label=""
            />
            <FormControlLabel
              onClick={() => {
                upDateNetworkType('vodafone');
              }}
              className={Styles.controlLabel}
              value="vodafone"
              control={
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <div>
                    <img
                      src={VodaFone}
                      alt="VodafoneLogo"
                      onLoad={e => {
                        e.preventDefault();
                        setShowShimmerEffect({
                          ...showShimmerEffect,
                          vodaLogo: false,
                        });
                      }}
                      className={Styles.logoStyle}
                    />
                    {showShimmerEffect.vodaLogo ? (
                      <Skeleton
                        variant="rectangular"
                        width={78}
                        height={41}
                        className={Styles.shimmerStyle}
                      />
                    ) : (
                      <></>
                    )}

                    <span className="chooseNetwork"></span>

                    <span
                      style={
                        networkType === 'vodafone'
                          ? {
                              background: '#1F6FDE',
                              border: '2px solid #fff',
                              transform: 'scale(1.5)',
                            }
                          : {
                              background: 'rgba(0,0,0,0.2)',
                              border: '2px solid rgba(255,255,255,0.09)',
                              transform: 'scale(1)',
                            }
                      }
                      className={Styles.checkMarkStyle}
                    >
                      <CheckMark fill="#fff" />
                    </span>
                  </div>
                  <span
                    style={
                      networkType === 'vodafone'
                        ? {
                            color: '#0f0f10',
                            fontSize: '14px',
                          }
                        : {
                            fontSize: '14px',
                            color: '#6F7689',
                          }
                    }
                  >
                    Vodafone
                  </span>
                </div>
              }
              label=""
            />
          </RadioGroup>
        </div>
        <div style={{marginBottom: ''}}>
          <p
            style={{
              fontWeight: '500',
              marginTop: '1.5rem',
              marginBottom: '0.6rem',
            }}
          >
            MoMo number:
          </p>
          <TextField
            placeholder="024 xxx xxxx"
            className={css`
              width: 65%;

              input {
                height: 1.5rem;
              }
              input::placeholder {
                font-size: 18px;
              }
              .MuiOutlinedInput-root {
                border-radius: 8px;
              }
              @media (max-width: 520px) {
                width: 100%;
              }
            `}
            variant="outlined"
            disabled={networkType === '' || requestOtpLoading}
            type="tel"
            {...inputfield}
            onChange={e => {
              inputfield.onChange(e);
              if (setPaymentError) setPaymentError(false);
              if (verifiedNumber) setVerifiedNumber(false);
              if (responseError) setResponseError(false);
              if (loadingSubmit) setLoadingSubmit(false);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <img
                    src={
                      networkType === 'mtn'
                        ? MTN
                        : networkType === 'vodafone'
                        ? VodaFone
                        : networkType === 'airtel-tigo'
                        ? AirtelTigo
                        : undefined
                    }
                    alt=""
                    style={{width: '30px', borderRadius: '3px'}}
                  />
                </InputAdornment>
              ),
            }}
            error={Boolean(errors.momoNumber) && true}
            helperText={
              <Typography
                variant="caption"
                style={{
                  display: 'inline-block',
                  marginLeft: '-0.9rem',
                  marginBottom: '1rem',
                }}
              >
                {errors.momoNumber && errors.momoNumber.message}
              </Typography>
            }
          />
        </div>
        {sendOTP ? (
          verifiedNumber ? (
            <div className={Styles.verifiedNumber}>
              <CheckMarkCircle fill="#2CBF1A" />{' '}
              <span className={Styles.verifiedNumberText}>
                Number is verified
              </span>
            </div>
          ) : (
            <>
              {getValues().momoNumber === undefined ||
              Boolean(errors.momoNumber) ? null : (
                <div>
                  <p style={{color: '#0F0F10', fontSize: '13.5px'}}>
                    Verify that you are the owner of this number
                  </p>
                  <button
                    onClick={sendOtpCode}
                    className={Styles.sendOtpBtn}
                    disabled={openVerify || requestOtpLoading}
                  >
                    {requestOtpLoading && (
                      <Lottie
                        aria-label="loading Spinner"
                        animationData={Spinner}
                        color="red"
                        style={{
                          maxWidth: '17%',
                        }}
                      />
                    )}
                    <span> Send an OTP</span>
                  </button>
                  {responseError ? (
                    <div className={Styles.errorContainer}>
                      <ErrorIcon />{' '}
                      <span
                        style={{
                          textAlign: 'center',
                          fontSize: '16px',
                        }}
                      >
                        {otpErrorMessage}
                      </span>
                    </div>
                  ) : null}
                </div>
              )}
            </>
          )
        ) : null}
        <div>
          <Divider />

          <div className={Styles.totalPriceContainer}>
            <Button
              onClick={() => {
                if (paymentError) setPaymentError && setPaymentError(false);
                navigate('/');
              }}
              className={'navigateBtn'}
              variant="contained"
              startIcon={<LeftArrow />}
            >
              Back
            </Button>
            <button
              disabled={disablePayButton() || loadingSubmit}
              type="submit"
              className={Styles.payBtn}
            >
              Pay GH₵ {totalPrice}
            </button>
          </div>
        </div>
      </form>
      {openVerify === true ? (
        <OtpModal
          Open={openVerify}
          setOpen={setOpenVerify}
          number={getValues().momoNumber}
          networkType={networkType}
          setIsVerified={setVerifiedNumber}
          onClose={closeVerifyModal}
          setResponseError={setResponseError}
          setOtpErrorMessage={setOtpErrorMessage}
        />
      ) : null}

      {openInitPayment === true ? (
        <InitializePaymentModal
          Open={openInitPayment}
          setOpen={setOpenInitPayment}
          number={getValues().momoNumber}
          networkType={networkType}
        />
      ) : null}
    </section>
  );
}

export default WithPage(PaymentDetails);
