import React, {PropsWithChildren} from 'react';
import PageNav from './components/PageNav';
import {css} from '@emotion/css';

const WithNav: React.FC<PropsWithChildren> = props => {
  return (
    <>
      <div
        className={css`
          @media screen and (min-width: 2500px) {
            max-width: 1500px;
            margin: 0 auto;
          }
        `}
      >
        <PageNav />
      </div>

      <>{props.children}</>
    </>
  );
};

export default WithNav;
