import React, {useState, useEffect} from 'react';
import Divider from '@mui/material/Divider';
import Styles from '../../../Styles/Carts.module.css';
import {css} from '@emotion/css';
import {useGlobalState} from '../../../context/AppContext';
import {useNavigate} from 'react-router-dom';

export default function Carts() {
  const {carts, itemCount, totalPrice} = useGlobalState();
  const [newCarts, setNewCarts] = useState(carts);
  const [showCart, setShowCart] = useState(true);
  const navigate = useNavigate();

  const collapseCart = () => {
    if (showCart) {
      if (itemCount! >= 3) {
        setShowCart(false);
        setTimeout(() => {
          const c = newCarts?.slice(0, 2);
          setNewCarts(c);
        }, 500);
      }
    } else {
      setNewCarts(carts);
      setShowCart(true);
    }
  };

  useEffect(() => {
    setNewCarts(carts);
  }, [carts]);

  if (carts?.length === 0) {
    navigate('/notfound');
  }
  return (
    <>
      <div className={Styles.carts}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
          onClick={collapseCart}
        >
          <div>
            <p className={Styles.cartsHeader}>Summary</p>
            <p className={Styles.cartsSubHeader}> {itemCount} items</p>
          </div>
          {!showCart ? (
            <span
              style={{color: '#1F6FDE', cursor: 'pointer', fontSize: '20px'}}
              onClick={e => {
                e.stopPropagation();
                collapseCart();
              }}
            >
              See all
            </span>
          ) : null}
        </div>
        <Divider
          style={{
            marginTop: '20px',
            borderColor: '#F2F3F7',
          }}
        />

        {/* {showCart ? ( */}
        <section
          className={`${Styles.cartDefault} ${showCart ? Styles.cartShow : ''}`}
        >
          <div className={Styles.cartFlexParent}>
            {newCarts?.map((cart, index) => (
              <div
                key={cart.name + cart.count.toString()}
                style={{display: 'flex', flexDirection: 'column'}}
              >
                <div
                  className={Styles.cartsItem}
                  style={
                    index !== newCarts.length - 1
                      ? {borderBottom: '1px solid #f2f3f7'}
                      : {borderBottom: '1px solid #f2f3f7'}
                  }
                >
                  <span>
                    {cart.name}
                    {+cart.count > 1 ? (
                      <span
                        className={css`
                          color: #6f7689;
                          margin-left: 15px;
                        `}
                      >
                        x{cart.count}
                      </span>
                    ) : (
                      ''
                    )}
                  </span>
                  <span>
                    GHC
                    <span className={Styles.cartsItemPrice}>
                      {cart.price.toLocaleString()}
                    </span>
                  </span>
                </div>
                {itemCount! >= 3 ? (
                  <span>
                    {newCarts.length === 2 && index === 1 ? (
                      <span>...</span>
                    ) : null}
                  </span>
                ) : null}
              </div>
            ))}
          </div>
        </section>
        <section className={Styles.cartsTotal}>
          <h1 style={{fontWeight: '400'}}>Total</h1>
          <h1 style={{fontWeight: '550'}}>
            GHS {totalPrice?.toLocaleString()}
          </h1>
        </section>
      </div>
    </>
  );
}
