import React from 'react';
import {FormControlLabel} from '@mui/material';
import Styles from '../../../src/Styles/pages/paymentType.module.css';
import {
  RightArrow,
  CheckMark,
  Phone,
  CardPayment,
} from '../../components/Icons/Index';

interface Props {
  setRadioValue: React.Dispatch<React.SetStateAction<string>>;
  radioValue: string;
}

export default function CardRadio(props: Props) {
  const {setRadioValue, radioValue} = props;

  return (
    <>
      <FormControlLabel
        onClick={() => {
          setRadioValue('card');
        }}
        style={
          radioValue === 'card' ? {background: '#f2f3f7'} : {background: ''}
        }
        className={Styles.radio}
        value="card"
        control={
          <span
            className={Styles.radioCircle}
            style={
              radioValue === 'card' ? {background: '#1F6FDE'} : {background: ''}
            }
          >
            <CheckMark style={{height: '2rem', width: '1rem'}} />
          </span>
        }
        label={
          <span className={Styles.radioLabel}>
            <CardPayment />
            <p
              style={
                radioValue !== 'card'
                  ? {marginTop: '-4px'}
                  : {
                      marginTop: '-4px',
                      fontWeight: 'bold',
                    }
              }
            >
              Card Payment
            </p>
          </span>
        }
      />
    </>
  );
}
