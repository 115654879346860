import React from 'react';
import {FormControlLabel} from '@mui/material';
import Styles from '../../../src/Styles/pages/paymentType.module.css';
import {
  RightArrow,
  CheckMark,
  Phone,
  CardPayment,
} from '../../components/Icons/Index';

interface Props {
  setRadioValue: React.Dispatch<React.SetStateAction<string>>;
  radioValue: string;
}
export default function MomoRadio(props: Props) {
  const {setRadioValue, radioValue} = props;
  return (
    <>
      <FormControlLabel
        onClick={() => {
          setRadioValue('momo');
        }}
        style={
          radioValue === 'momo' ? {background: '#f2f3f7'} : {background: ''}
        }
        className={Styles.radio}
        value="momo"
        control={
          <span
            className={Styles.radioCircle}
            style={
              radioValue === 'momo' ? {background: '#1F6FDE'} : {background: ''}
            }
          >
            <CheckMark style={{height: '2rem', width: '1rem'}} />
          </span>
        }
        label={
          <span className={Styles.radioLabel}>
            <Phone />
            <p
              style={
                radioValue !== 'momo'
                  ? {marginTop: '1px'}
                  : {
                      fontWeight: 'bold',
                    }
              }
            >
              Mobile Money
            </p>
          </span>
        }
      />
    </>
  );
}
