import * as React from 'react';

interface Props {
  fill?: string;
  style?: {[key: string]: string | number};
}
const CreditCard = (props: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24.078"
    height="19.263"
    style={props.style}
  >
    <path
      d="M21.671 0H2.408a2.389 2.389 0 0 0-2.4 2.408L0 16.855a2.4 2.4 0 0 0 2.408 2.408h19.263a2.4 2.4 0 0 0 2.408-2.408V2.408A2.4 2.4 0 0 0 21.671 0Zm0 16.855H2.408V9.631h19.263Zm0-12.039H2.408V2.408h19.263Z"
      fill={props.fill || '#1f6fde'}
    />
  </svg>
);

export default CreditCard;
