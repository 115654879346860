import React, {useEffect} from 'react';
import {Routes, Route} from 'react-router-dom';
import PaymentType from './Pages/PaymentType';
import PaymentDetails from './Pages/PaymentDetails';
import NotFound from './Pages/SessionError';
import SuccessPage from './Pages/Success';
import CardPayment from './Pages/CardPayment';
import Civil from './Pages/Civil';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useNavigate} from 'react-router-dom';
import {useGlobalState} from './context/AppContext';
import CitiFm from './Pages/CitiTv';

function App() {
  const navigate = useNavigate();

  const {sessionExpiryTime} = useGlobalState();
  const [currentTime, setCurrentTime] = React.useState(new Date());

  const tick = () => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(interval);
  };

  useEffect(() => {
    tick();
    if (currentTime > sessionExpiryTime) {
      localStorage.removeItem('NSPexpiryTime');
      localStorage.removeItem('payload');
      navigate('/notfound');
    }
  }, [currentTime]);
  return (
    <>
      <Routes>
        <Route path="/" element={<PaymentType />} />
        <Route path="/payment-details" element={<PaymentDetails />} />
        <Route path="/card-payment" element={<CardPayment />} />
        <Route path="/success" element={<SuccessPage />} />
        <Route path="/cititv" element={<CitiFm />} />
        <Route path="/civil" element={<Civil />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <ToastContainer />
    </>
  );
}

export default App;
