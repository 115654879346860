import * as React from 'react';

interface Props {
  fill?: string;
  style?: {[key: string]: string | number};
}

const CheckMarkCircle = (props: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="28"
    style={props.style}
  >
    <path
      data-name="Icon ionic-ios-checkmark-circle"
      d="M14 0a14 14 0 1 0 14 14A14 14 0 0 0 14 0Zm7.168 10.125-9 9.039h-.007a1.216 1.216 0 0 1-.781.37 1.178 1.178 0 0 1-.788-.384l-3.767-3.763a.268.268 0 0 1 0-.384l1.2-1.2a.26.26 0 0 1 .377 0l2.988 2.988 8.21-8.266a.266.266 0 0 1 .188-.081.244.244 0 0 1 .188.081l1.178 1.218a.265.265 0 0 1 .014.382Z"
      fill={props.fill || '#f2f3f7'}
    />
  </svg>
);

export default CheckMarkCircle;
