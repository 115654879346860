import React, {useRef} from 'react';
import LogoComponent from '../../Icons/Logo';
import Styles from '../../../Styles/PageNav.module.css';
import HelpOutline from '../../Icons/HelpOutline';

export default function Nav() {
  const navRef = useRef<HTMLDivElement | null>(null);
  window.addEventListener('scroll', () => {
    if (navRef.current) {
      navRef.current.style.borderBottom = '1px solid #ccc';
    }
    if (window.scrollY === 0) {
      navRef.current!.style.borderBottom = 'none';
    }
  });

  return (
    <header ref={navRef}>
      <div id="PageNav" className={Styles.navContainer}>
        <section id="logo" className={Styles.navLogo}>
          <LogoComponent />
        </section>

        <section id="helpLink" className={Styles.navHelp}>
          <HelpOutline />
          <span>Help</span>
        </section>
      </div>
    </header>
  );
}
