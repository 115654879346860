import React, {useEffect} from 'react';
import WithNav from '../components/Layouts/WithNav';
import Styles from '../Styles/pages/Success.module.css';
import CheckMarkCircle from '../components/Icons/CheckMarkCircle';
import {useGlobalState} from '../context/AppContext';

const SuccessPage: React.FC = () => {
  const {referral_url} = useGlobalState();
  useEffect(() => {
    setTimeout(() => {
      window.location.href = referral_url;
      localStorage.removeItem('payload');
    }, 3000);
  }, []);

  return (
    <WithNav>
      <div className={Styles.SuccessPage}>
        <div className={Styles.SuccessPageContainer}>
          <div className={Styles.SuccessPageHeader}>
            <CheckMarkCircle fill="#18A306" />
            <p className={Styles.SuccessPageHeaderTitle}>
              {' '}
              Your payment was successful
            </p>
          </div>

          <div className={Styles.SuccessPageBody}>
            <p>
              Your payment has been recorded successfully. You will be
              automatically redirected back to your portal.
            </p>
            <div style={{display: 'flex', gap: '0.5rem', fontSize: '16px'}}>
              <p>Or</p>
              <a href={referral_url}> Click here to head back</a>
            </div>
          </div>
        </div>
      </div>
    </WithNav>
  );
};
export default SuccessPage;
