import * as React from 'react';

interface Props {
  fill?: string;
  style?: {[key: string]: string | number};
}
const HelpOutline = (props: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25.999"
    height="26"
    style={props.style}
  >
    <path
      d="M0 13A13 13 0 0 1 13 0a13 13 0 0 1 13 13 13 13 0 0 1-13 13A13 13 0 0 1 0 13Zm5.044-7.957A11.18 11.18 0 0 0 1.75 13a11.176 11.176 0 0 0 3.294 7.956A11.176 11.176 0 0 0 13 24.25a11.176 11.176 0 0 0 7.956-3.294A11.176 11.176 0 0 0 24.25 13a11.18 11.18 0 0 0-3.294-7.957A11.175 11.175 0 0 0 13 1.75a11.175 11.175 0 0 0-7.956 3.293ZM11.2 18.301a1.473 1.473 0 0 1 1.5-1.448 1.47 1.47 0 0 1 1.512 1.448A1.47 1.47 0 0 1 12.7 19.75a1.464 1.464 0 0 1-1.5-1.449Zm.246-2.678-.021-.6a2.929 2.929 0 0 1 1.66-3.094c1.188-.71 1.688-1.16 1.688-2.031a1.707 1.707 0 0 0-1.892-1.512 1.78 1.78 0 0 0-1.885 1.73h-2.5c.049-2.264 1.723-3.86 4.549-3.86 2.636 0 4.451 1.463 4.451 3.565a3.438 3.438 0 0 1-1.976 3.121c-1.224.711-1.639 1.231-1.639 2.131v.555Z"
      fill={props.fill || '#0f0f10'}
    />
  </svg>
);

export default HelpOutline;
