import React, {useState} from 'react';
import Styles from '../Styles/pages/paymentType.module.css';
import {
  Divider,
  Button,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {css} from '@emotion/css';
import {useGlobalState} from '../context/AppContext';
import Lottie from 'lottie-react';
import Spinner from '.././Assets/lotties/Spinner.json';
import {toast} from 'react-toastify';
import {useForm} from 'react-hook-form';

import {
  RightArrow,
  CheckMark,
  Phone,
  CardPayment,
} from '../components/Icons/Index';
import MomoRadio from '../Forms/PaymentType/MomoRadio';
import CardRadio from '../Forms/PaymentType/CardRadio';
import WithPage from '../components/Layouts/WithPage';
import {
  getSessionId,
  sendCardPaymentStatus,
  addCardUserEmail,
} from '../api/cardpay';

function PaymentType() {
  const {id, paymentType, setPaymentType, payment_option} = useGlobalState();

  const [radioValue, setRadioValue] = useState<string>('');
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  type FormValues = {
    cardUserEmail: string;
  };
  const {
    register,
    handleSubmit,
    reset,
    // setValue,
    getValues,
    formState: {errors},
  } = useForm<FormValues>({
    mode: 'all',
  });
  const emailfield = register('cardUserEmail', {
    required: {
      value: radioValue === 'card' ? true : false,
      message: 'Email is required',
    },
    validate: value => {
      if (radioValue === 'card') {
        if (value.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i)) {
          return true;
        } else {
          return 'Please enter a valid email address';
        }
      } else {
        return true;
      }
    },
  });

  const addCallbacks = () => {
    window.completeCallback = async () => {
      await sendCardPaymentStatus({
        data: {
          id: id,
          status: 'PAID',
        },
        onSuccess: res => {},
        onError: err => {},
      });
      navigate('/success');
    };
    window.errorCallback = async () => {
      await sendCardPaymentStatus({
        data: {
          id: id,
          status: 'FAILED',
        },
        onSuccess: res => {},
        onError: err => {},
      });
      navigate('/notFound');
      localStorage.removeItem('payload');
    };
  };
  const formSubmit = async () => {
    setLoading(true);

    if (radioValue === 'momo') {
      navigate('/payment-details');
      setPaymentType &&
        setPaymentType({
          selected: true,
          type: 'momo',
        });
      setLoading(false);
    } else {
      await addCardUserEmail({
        data: {
          id: id,
          email: getValues('cardUserEmail'),
        },
        onSuccess: res => {},
        onError: err => {},
      });
      addCallbacks();
      setPaymentType &&
        setPaymentType({
          selected: true,
          type: 'card',
        });
      await getSessionId({
        data: {
          id: id,
        },
        onSuccess: res => {
          if (res.status === 200) {
            window.Checkout!.configure({
              session: {
                id: res.data.response.split('&')[3].split('=')[1],
              },
            });

            navigate('/card-payment');
            setLoading(false);
          } else {
            setLoading(false);
            toast.error(
              'An error occurred processing your request. Please try again',
              {
                position: toast.POSITION.TOP_RIGHT,
              }
            );
          }
        },
        onError: err => {
          setLoading(false);
          toast.error(
            'An error occurred processing your request. Please try again',
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
        },
      });
    }
  };

  React.useEffect(() => {
    if (paymentType?.selected) {
      setRadioValue(paymentType?.type);
    }
  }, []);

  return (
    <section
      style={{
        marginTop: '3rem',
      }}
    >
      <form onSubmit={handleSubmit(formSubmit)}>
        <div style={{width: '100%'}}>
          <p
            className={`pageTitle
          `}
          >
            Payment type
          </p>
          <p className="pageSubTitle">Choose a payment type</p>
          <Divider />
        </div>
        <div
          className={css`
            margin-top: 20px;
          `}
        >
          <RadioGroup
            aria-labelledby="demo-error-radios"
            name="quiz"
            value={radioValue}
          >
            {payment_option === 'momo' ? (
              <MomoRadio
                radioValue={radioValue}
                setRadioValue={setRadioValue}
              />
            ) : payment_option === 'card' ? (
              <CardRadio
                radioValue={radioValue}
                setRadioValue={setRadioValue}
              />
            ) : (
              <>
                <MomoRadio
                  radioValue={radioValue}
                  setRadioValue={setRadioValue}
                />

                <CardRadio
                  radioValue={radioValue}
                  setRadioValue={setRadioValue}
                />
              </>
            )}

            {radioValue === 'card' ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <TextField
                  {...emailfield}
                  className={css`
                    .MuiInputBase-root {
                      border-radius: 8px;
                    }
                  `}
                  style={{
                    marginTop: '20px',
                    marginLeft: '-11px',
                  }}
                  onChange={e => {
                    emailfield.onChange(e);
                  }}
                  label="Your Email"
                  type="email"
                  fullWidth
                  error={errors.cardUserEmail ? true : false}
                  helperText={
                    <Typography
                      variant="caption"
                      style={{
                        display: 'inline-block',
                        marginLeft: '-0.9rem',
                        marginBottom: '1rem',
                      }}
                    >
                      {errors.cardUserEmail && errors.cardUserEmail.message}
                    </Typography>
                  }
                />

                <span
                  style={{
                    color: 'rgb(0, 63, 153)',
                    fontSize: '0.9rem',
                    marginLeft: '-0.8rem',
                  }}
                >
                  Enter a valid email address to receive your receipt
                </span>
              </div>
            ) : null}
          </RadioGroup>
        </div>
        <div
          className={css`
            margin-top: 30px;
            @media (max-width: 900px) {
              margin-bottom: 50px;
            }
          `}
        >
          <Divider />

          <Button
            disabled={radioValue === '' || loading === true}
            startIcon={
              loading === true ? (
                <Lottie
                  aria-label="loading Spinner"
                  animationData={Spinner}
                  style={{
                    maxWidth: '23%',
                  }}
                />
              ) : null
            }
            type="submit"
            style={{marginTop: '40px', marginBottom: '20rem'}}
            className={`navigateBtn
          `}
            variant="contained"
            endIcon={<RightArrow />}
          >
            Next
          </Button>
        </div>
      </form>
    </section>
  );
}

export default WithPage(PaymentType);
