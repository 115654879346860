import React from 'react';

interface Props {
  fill?: string;
  style?: {[key: string]: string | number};
}
function ErrorIcon(props: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25.599"
      height="25.599"
      viewBox="0 0 25.599 25.599"
      style={props.style}
    >
      <path
        fill={props.fill || '#db1818'}
        d="M15.8 3a12.8 12.8 0 1012.8 12.8A12.8 12.8 0 0015.8 3zm1.28 19.2h-2.56v-2.56h2.56zm0-5.12h-2.56V9.4h2.56z"
        data-name="Icon material-error"
        transform="translate(-3 -3)"
      ></path>
    </svg>
  );
}

export default ErrorIcon;
