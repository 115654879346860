import * as React from 'react';

interface Props {
  fill?: string;
  style?: {[key: string]: string | number};
}

const CardPayment = (props: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19.975"
    height="14.981"
    style={props.style}
  >
    <path
      data-name="Path 1"
      d="M18.726 0H1.248A1.248 1.248 0 0 0 0 1.248v12.485a1.248 1.248 0 0 0 1.248 1.248h17.478a1.248 1.248 0 0 0 1.248-1.248V1.248A1.248 1.248 0 0 0 18.726 0ZM3.433 3.121h3.745a.312.312 0 0 1 .312.312v2.5a.312.312 0 0 1-.312.312H3.433a.312.312 0 0 1-.312-.312v-2.5a.312.312 0 0 1 .312-.312ZM10.3 11.86H3.433a.312.312 0 0 1-.312-.312v-.624a.312.312 0 0 1 .312-.312H10.3a.312.312 0 0 1 .312.312v.624a.312.312 0 0 1-.312.312Zm.936-6.242a.624.624 0 1 1 .624-.624.624.624 0 0 1-.624.624Zm1.532-1.476 1.1-.614a2.457 2.457 0 0 1 0 2.93l-1.1-.614a1.235 1.235 0 0 0 0-1.702Zm3.773 7.718H13.42a.312.312 0 0 1-.312-.312v-.624a.312.312 0 0 1 .312-.312h3.121a.312.312 0 0 1 .312.312v.624a.312.312 0 0 1-.312.312Zm-.477-4.182-1.09-.606a3.733 3.733 0 0 0 0-4.153l1.09-.609a4.956 4.956 0 0 1 0 5.368Z"
      fill={props.fill || '#0f0f10'}
    />
  </svg>
);

export default CardPayment;
