import React, {useEffect} from 'react';
import Styles from '../../Styles/modals/InitPaymentModal.module.css';
import BaseModal from './Index';
import {useNavigate} from 'react-router-dom';
import Lottie from 'lottie-react';
import Spinner from '../.././Assets/lotties/Spinner.json';
import {useGlobalState} from '../../context/AppContext';
import {MakePayment} from '../../api/payment';
import {toGHInternationalFormat} from '../../utils/validation';
import {socket} from '../../utils/apiaxios';

type Props = {
  Open: boolean;
  setOpen: (value: boolean) => void;
  number: string | number;
  networkType: string;
};

const InitializePaymentModal = (props: Props) => {
  const navigate = useNavigate();
  const {setPaymentError, id} = useGlobalState();

  const throwError = () => {
    setPaymentError && setPaymentError(true);
    props.setOpen(false);
  };

  socket.on('payment-status', data => {
    // const d = JSON.parse(data);
    if (data.Status === 'PAID' || data.status === 'Approved') {
      navigate('/success');
      localStorage.removeItem('payload');
    } else {
      throwError();
    }
  });

  return (
    <BaseModal Open={props.Open} backdropBackgroundColor={'rgba(0,0,0,0.5)'}>
      <main
        onClick={e => {
          e.stopPropagation();
        }}
        className={Styles.InitPaymentModal}
      >
        <div className={Styles.InitPaymentModalContent}>
          <Lottie
            aria-label="loading Spinner"
            animationData={Spinner}
            className={Styles.InitPaymentModalSpinner}
          />
          <h2>Initiating Payment...</h2>
        </div>
        <p style={{alignSelf: 'center'}}>
          Kindly follow the prompt sent to {props.number} to complete your
          payment
        </p>
      </main>
    </BaseModal>
  );
};

export default InitializePaymentModal;
