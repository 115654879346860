import React, {useState, useEffect} from 'react';
import Styles from '../../Styles/modals/OtpModal.module.css';
import BaseModal from './Index';
import {css} from '@emotion/css';
import {Close} from '../Icons/Index';
import {useForm} from 'react-hook-form';
import Lottie from 'lottie-react';
import Spinner from '../.././Assets/lotties/Spinner.json';
import {VerifyOTp, SendOTP} from '../../api/otp';
import {useGlobalState} from '../../context/AppContext';
import {toGHInternationalFormat} from '../../utils/validation';

type Props = {
  Open: boolean;
  setOpen: (value: boolean) => void;
  number: string | number;
  networkType: string;
  setIsVerified: (value: boolean) => void;
  onClose: () => void;
  setResponseError: (value: boolean) => void;
  setOtpErrorMessage: (value: string) => void;
};

type FormValues = {
  otpValue: number;
};

const OtpModal = (props: Props) => {
  const {id} = useGlobalState();
  const [timeLeft, setTimeLeft] = useState<number>(120);
  const [timerId, setTimerId] = useState<NodeJS.Timeout | null>();
  const [verifyOtpLoader, setVerifyOtpLoader] = useState<Boolean>(false);
  const [disableResend, setDisableResend] = useState<boolean>(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    setTimerId(intervalId);

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  const {
    register,
    handleSubmit,
    reset,
    // setValue,
    getValues,
    formState: {errors},
  } = useForm<FormValues>({
    mode: 'all',
  });
  const throwError = (message: string) => {
    setVerifyOtpLoader(false);
    props.setOtpErrorMessage(message);
    props.setResponseError(true);
    props.setIsVerified(false);
    props.setOpen(false);
    reset();
    setTimeLeft(120);
  };
  const onSubmit = (data: FormValues) => {
    setVerifyOtpLoader(true);
    VerifyOTp({
      data: {id: id, code: data.otpValue},
      onSuccess: res => {
        if (res.status === 200) {
          if (res.data) {
            setVerifyOtpLoader(false);
            props.setIsVerified(true);
            props.setOpen(false);
            reset();
            setTimeLeft(120);
          } else {
            throwError('Invalid OTP');
          }
        } else {
          throwError('Invalid OTP');
        }
      },
      onError(err) {
        throwError('An error occured, please try again');
      },
    });
  };

  const resendOTP = () => {
    setDisableResend(true);
    SendOTP({
      data: {
        id: id,
        payby: props.networkType.toUpperCase(),
        customer_number: toGHInternationalFormat(props.number),
      },
      onSuccess: res => {
        setDisableResend(false);
        if (res?.status === 200) {
          reset();
          setTimeLeft(120);
          setVerifyOtpLoader(false);
        } else {
          throwError('An error occured, please try again');
        }
      },
      onError: err => {
        setDisableResend(false);
        throwError('An error occured, please try again');
      },
    });
  };

  return (
    <BaseModal Open={props.Open} backdropBackgroundColor={'rgba(0,0,0,0.5)'}>
      <div className={Styles.otpModalContainer}>
        <main
          onClick={e => {
            e.stopPropagation();
          }}
          className={Styles.OtpModal}
        >
          <div
            onClick={() => {
              props.onClose();
              setTimeLeft(120);
              reset();
            }}
            aria-label="Close Modal"
            className={Styles.CloseModal}
          >
            <Close className={Styles.CloseModalIcon} />
          </div>
          <p className={Styles.OtpModalTitle}>
            Verify your Mobile Money Number
          </p>

          <p
            className={css`
              font-size: 14px;
            `}
          >
            We have sent an OTP to your number{' '}
            <span style={{fontWeight: '500'}}>{props.number}</span>
          </p>
          <p
            className={css`
              font-size: 14px;
            `}
          >
            Please enter the 6 digit number in the field provided below
          </p>

          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <div>
              <label
                htmlFor="otp"
                style={{
                  display: 'block',
                  fontWeight: '500',
                  marginBottom: '0.3rem',
                }}
              >
                OTP:
              </label>
              <input
                type="tel"
                id="otp"
                placeholder="--- ---"
                className={Styles.OtpInput}
                {...register('otpValue', {
                  required: {
                    value: true,
                    message: 'Please enter your OTP number',
                  },
                  maxLength: {
                    value: 6,
                    message: 'Please enter a valid OTP number',
                  },
                  minLength: {
                    value: 6,
                    message: 'Please enter a valid OTP number',
                  },
                  validate: value => {
                    const v = value.toString();
                    if (v.match(/^[0-9]+$/)) {
                      return true;
                    } else {
                      return 'Please enter a valid OTP number';
                    }
                  },
                })}
              />
            </div>
            <div
              style={{
                fontSize: '14px',
                marginTop: '0.7rem',
                marginBottom: '2rem',
              }}
            >
              {timeLeft <= 0 ? (
                <span
                  style={{display: 'flex', justifyContent: 'space-between'}}
                >
                  <span style={{color: 'red', fontSize: '14px'}}>Timeout</span>{' '}
                  <button
                    disabled={disableResend}
                    className={Styles.ResendOTP}
                    onClick={resendOTP}
                  >
                    Send a new OTP
                  </button>
                </span>
              ) : (
                <>
                  OTP timeout in{' '}
                  <span style={{fontWeight: '500'}}>
                    {Math.floor(timeLeft / 60)}:
                    {(timeLeft % 60).toString().padStart(2, '0')}
                  </span>
                </>
              )}
            </div>

            <button
              type="submit"
              disabled={
                getValues('otpValue') === undefined ||
                isNaN(getValues('otpValue')) ||
                getValues('otpValue').toString().length !== 6 ||
                !getValues('otpValue')
                  .toString()
                  .match(/^[0-9]+$/) ||
                timeLeft <= 0
                  ? true
                  : false
              }
              className={Styles.VerifyOTP}
            >
              {verifyOtpLoader && timeLeft > 0 ? (
                <Lottie
                  aria-label="loading Spinner"
                  animationData={Spinner}
                  className={Styles.VerifyOtpLoader}
                />
              ) : null}
              <span style={{fontSize: '22px'}}>
                {verifyOtpLoader && timeLeft > 0 ? 'verifying....' : 'verify'}
              </span>
            </button>
          </form>
        </main>
      </div>
    </BaseModal>
  );
};

export default OtpModal;
