import React, {useState} from 'react';
import APIAxios from '../utils/apiaxios';
import {AxiosResponse, AxiosError} from 'axios';
import Styles from '../Styles/pages/citiTv.module.css';
import Logo from '../Assets/Images/civil.jpeg';

interface CivilArgs {
  data: {
    amount: number | string;
    referral_url: string;
  };
  onSuccess: (res: AxiosResponse) => void;
  onError: (err: AxiosError) => void;
}
const CivilCheckout = async (args: CivilArgs) => {
  await APIAxios({
    url: '/civil-checkout',
    method: 'post',
    data: args.data,
  })
    .then(args.onSuccess)
    .catch(args.onError);
};

export default function Civil() {
  const [amount, setAmount] = useState<string | number>('');
  const [errroMessage, setErrroMessage] = useState<string>('');

  const checkout = () => {
    setErrroMessage('');
    const data = {
      amount,
      referral_url: 'https://www.cscafcfta.org/',
    };
    if (amount === 0 || amount === '') {
      //todo: throw error
      setErrroMessage('invalid amount');
    } else {
      CivilCheckout({
        data: data,
        onSuccess: res => {
          window.location.href = res.request.responseURL;
        },
        onError: err => {
          //todo: throw error
        },
      });
    }
  };
  return (
    <div className={Styles.container}>
      <main className={Styles.pageContainer}>
        <span className={Styles.close}>X</span>
        <section className={Styles.logoSection}>
          <div className={Styles.Logo}>
            <img src={Logo} alt="Civil Logo" />
          </div>
          <div className={Styles.logoNotes}>
            <h2>Your donation makes a difference</h2>
            <p>Pledge your support today so that we can help more.</p>
          </div>
        </section>

        <section className={Styles.donateSection}>
          <input
            type="number"
            placeholder="Amount"
            value={amount}
            onChange={e => {
              setErrroMessage('');
              setAmount(e.target.value);
            }}
          />
          <span
            style={{
              color: 'red',
              marginTop: '-20px',
              marginBottom: '-20px',
            }}
          >
            {errroMessage}
          </span>
          <button onClick={checkout}>Donate</button>
        </section>
      </main>
    </div>
  );
}
