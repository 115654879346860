import React from 'react';
import {FormatList, CreditCard} from '../../Icons/Index';
import Styles from '../../../Styles/PaymentNav.module.css';
import {useLocation} from 'react-router-dom';

export default function PaymentNav() {
  const location = useLocation();
  return (
    <div>
      <main className={Styles.PaymentNavContainer}>
        <section className={Styles.PaymentNavSection}>
          <span
            style={{
              background: '#d8e8ff ',
            }}
            className={`centered ${Styles.PaymentNavSectionIcon}`}
          >
            <CreditCard />
          </span>
          <span
            style={
              location.pathname === '/' ? {color: '#000'} : {color: '#6f7689'}
            }
            className={Styles.PaymentNavSectionText}
          >
            <p>Step 1</p>
            <p>Payment type</p>
          </span>
        </section>
        <section className={Styles.PaymentNavSection}>
          <span
            style={{
              background: '#d9fcd5 ',
            }}
            className={`centered ${Styles.PaymentNavSectionIcon}`}
          >
            <FormatList />
          </span>
          <span
            style={
              location.pathname === '/payment-details' ||
              location.pathname === '/card-payment'
                ? {color: '#000'}
                : {color: '#6f7689'}
            }
            className={Styles.PaymentNavSectionText}
          >
            <p>Step 2</p>
            <p>Payment details</p>
          </span>
        </section>
      </main>
    </div>
  );
}
