import React from 'react';
import {css} from '@emotion/css';

interface Props {
  onBackDropClick?: () => void;
  Open: boolean;
  backdropBackgroundColor?: string;
  children: React.ReactNode;
}

const BaseModal = (props: Props) => {
  return props.Open ? (
    <div
      className={css`
        position: fixed;
        z-index: 15;
        background: ${props.backdropBackgroundColor
          ? props.backdropBackgroundColor
          : 'rgba(0, 0, 0, 0.5)'};
        width: 100%;
        height: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      `}
      onClick={props.onBackDropClick}
    >
      {props.children}
    </div>
  ) : null;
};

export default BaseModal;
