import React from 'react';
import Styles from '../Styles/pages/SessionError.module.css';
import WithNav from '../components/Layouts/WithNav';

const SessionError: React.FC = () => {
  return (
    <WithNav>
      <div className={Styles.SessionError}>
        <p>
          There seems to be a problem with your session. Please try issuing the
          request again
        </p>

        <p>If the problem persists try clearing your browser cookies</p>
      </div>
    </WithNav>
  );
};

export default SessionError;
